@import "~normalize.css/normalize.css";


body {
    background: #eef2f6 !important;
    height: 100vh;
}
/**
* Do not remove this is for content/event open to stop background scrolling while keeping position of screen
*/
.modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(83, 83, 83, 0.6);
  z-index: 10000;
}

html.modal-open {
  overflow-y: hidden !important;
  height: 100% !important;
}
* {
    font-family: 'Roboto', sans-serif;
}
.flex-col {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}
.is-col-group {
    flex: 1;
}
.is-col-group-scroll {
    overflow: scroll;
}
.fc-button-primary {
    color: #000 !important;
    background-color: transparent !important;
    border: none !important;
}
.calendar-days {
    text-align: center;
    font-weight: bold;
    color: #AEB5BC;
    font-size: 14px;
}
.react-add-to-calendar {
  z-index: 1;
  position: absolute;
  background-color: white;
}
.react-add-to-calendar__dropdown {
  box-shadow: 0px 0px 7px rgba(39, 43, 52, 0.08);
}
.react-add-to-calendar__dropdown li:hover {
  background-color: #eef2f6;
}

.message-icon {
  background-image: url('images/message.svg');
  height: 24px;
  width: 24px;
}

.alert-large__icon {
  height: 64px !important;
  width: 56px !important;
}

.alert-icon__default {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.alert-two__icons {
  margin-left: 16px;
}

.alert-broadcast {
  background-image: url('images/broadcast.svg');
}

.alert-sms {
  background-image: url('images/sms.svg');
}

#play-button {
  background-image: url('images/play-circle.svg');
}

#pause-button {
  background-image: url('images/pause-circle.svg');
}

/* Mentions */

.mentions-dropdown__container {
  width: 375px;
  box-shadow: 0px 2px 6px #CCCCCC;
  background-color: #fff;

  max-height: 30%;
  overflow-y: scroll;
  z-index: 10000000;
}
.mentions-dropdown__container * {
  margin-bottom: 0;
}
.mentions-dropdown__container .mentions-dropdown__name {
  font-size: 16px;
}

.mentions-dropdown__item {
  height: 56px;
  display: flex;
  align-items: center;

  cursor: pointer;

  padding: 8px;
}

.mentions-dropdown__initials {
  height: 40px;
  width: 40px;
  background-color: #9B1921;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 18px;
  font-weight: bold;

  margin-right: 8px;
}

.mentions-dropdown__highlighted {
  background-color: #EEF2F6;
}

.mentions-highlighted {
  color: #3FA8F0;

  border: none;
  outline: none;
  background: transparent;
  padding: 0;

  font-size: inherit;
  font-weight: 400;
  font-family: sans-serif;
}

.mentions-highlighted__bold {
  color: black;
  font-weight: bold;

  border: none;
  outline: none;
  background: transparent;
  padding: 0;

  font-size: inherit;
  font-family: sans-serif;
}

.info-modal__container-mentions {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 10;
}

.modal-container__mentions {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

/* End of mentions */

@media screen and (min-width: 769px) and (max-width: 1023.5px) {
  .is-hidden-tablet-only {
    display: none !important;
  }
}

.news-feed-mid-column {
  margin: 0 .5rem;
}

.news-feed-side-column {
  flex: 1 1 25% !important;
}
