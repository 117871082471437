@media screen and (min-width: 768px) {
    .content-preview .vjs-theme-apprise.vjs-fluid {
        width: 100% !important;
        height: 100% !important;
        padding: 0 !important;
    }
}
.vjs-theme-apprise .vjs-big-play-button {
    background-color: transparent;
    width: 79px;
    height: 79px;
    top: 50%;
    left: 0;
    right: 0;
    background-image: url('../../images/play-circle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 78px;
    border: none !important;
    box-shadow: none !important;
    transform: translateY(-50%);
    margin: 0 auto;
}
.vjs-theme-apprise:hover .vjs-big-play-button {
    background-color: transparent;
}

.vjs-theme-apprise .vjs-big-play-button .vjs-icon-placeholder::before {
    content: "";
    display: none;
}
.vjs-theme-apprise .vjs-big-play-button .vjs-icon-placeholder:hover {
    background-color: transparent;
    opacity: 0.7;
}


.vjs-theme-apprise .vjs-control-bar {
    height: 100%;
    background: linear-gradient(360deg, #090B0D 0%, rgba(9, 11, 13, 0) 81.03%);
}

.vjs-theme-apprise .vjs-button > .vjs-icon-placeholder::before {
    line-height: 50px;
}

.vjs-theme-apprise .vjs-play-progress::before {
    display: none;
}

.vjs-theme-apprise .vjs-progress-control {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 16px;
}

.vjs-theme-apprise .vjs-progress-holder {
    height: 1rem !important;
}

.vjs-theme-apprise .vjs-progress-control .vjs-progress-holder {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0;
}

.vjs-theme-apprise .vjs-play-progress {
    background-color: #9B1921;
}

.vjs-theme-apprise .vjs-remaining-time {
    order: 1;
    line-height: 50px;
    flex: 3;
    text-align: left;
}

.vjs-theme-apprise .vjs-current-time {
    display: block;
    padding-right: 0;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #9B1921;
    position: absolute;
    bottom: 16px;
    height: 34px;
}
.vjs-theme-apprise .vjs-current-time::after {
    content: "/";
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #AEB5BC
}
.vjs-theme-apprise .vjs-duration {
    display: block;
    padding-left: 0;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #AEB5BC;
    position: absolute;
    height: 34px;
    bottom: 16px;
    left: 53px;
}

.vjs-theme-apprise .vjs-play-control {
    order: 2;
    flex: 8;
    font-size: 1.75em;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    top: 50%;
    width: 79px;
    height: 79px;
    background-repeat: no-repeat;
    background-size: 79px;
}

.vjs-theme-apprise .vjs-play-control .vjs-icon-placeholder:before {
    content: "";
    background-image: url('../../images/play-circle.svg');
    background-size: 79px;
}

.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before, .vjs-icon-pause:before {
    content: "";
    background-image: url('../../images/pause-circle.svg');
    background-size: 79px;
}

.vjs-theme-apprise .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
    content: "";
    background-image: url('../../images/replay.svg');
    background-size: 79px;
    transform: scaleX(-1);
}


.vjs-theme-apprise .vjs-volume-control {
    position: absolute;
    right: 0;
    bottom: 16px;
    height: 34px;
    text-align: right;
    display: inline-block;
    margin: 0;
    z-index: 0;
}

.vjs-theme-apprise .vjs-poster {
    /*background-size: cover;*/
    max-width: 100%;
    max-height: 100%;
}

.vjs-theme-apprise .vjs-volume-panel,
.vjs-theme-apprise .vjs-picture-in-picture-control,
.vjs-theme-apprise .vjs-fullscreen-control {
    order: 3;
    flex: 1;
    display: inline-block;
    flex: none;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 30px;
}

.vjs-theme-apprise .vjs-fullscreen-control[title~="Fullscreen"] {
    background-image: url('../../images/expand.svg');
}

.vjs-theme-apprise .vjs-fullscreen-control[title~="Non-Fullscreen"] {
    background-image: url('../../images/compress.svg');
}

.vjs-theme-apprise .vjs-fullscreen-control .vjs-icon-placeholder:before {
    content: "";
}

.vjs-theme-apprise.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
    content: "";
}

/* Volume stuff */
.vjs-theme-apprise .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
    height: 100%;
}

.vjs-theme-apprise .vjs-mute-control {
    display: none;
}

.vjs-theme-apprise .vjs-volume-panel {
    margin-left: 0.5em;
    margin-right: 0.5em;
    padding-top: 1.5em;
}

.vjs-theme-apprise .vjs-volume-panel,
.vjs-theme-apprise .vjs-volume-panel:hover,
.vjs-theme-apprise .vjs-volume-panel.vjs-volume-panel-horizontal:hover,
.vjs-theme-apprise .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal,
.vjs-theme-apprise .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal,
.vjs-theme-apprise .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal,
.vjs-theme-apprise .vjs-volume-panel.vjs-volume-panel-horizontal:hover,
.vjs-theme-apprise .vjs-volume-bar.vjs-slider-horizontal {
    width: 3em;
}

.vjs-theme-apprise .vjs-volume-level::before {
    font-size: 1em;
}

.vjs-theme-apprise .vjs-volume-panel .vjs-volume-control {
    opacity: 1;
    width: 100%;
    height: 100%;
}

.vjs-theme-apprise .vjs-volume-bar {
    background-color: transparent;
    margin: 0;
}

.vjs-theme-apprise .vjs-slider-horizontal .vjs-volume-level {
    height: 100%;
}

.vjs-theme-apprise .vjs-volume-bar.vjs-slider-horizontal {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
}

.vjs-theme-apprise .vjs-volume-bar::before {
    content: '';
    z-index: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 0px;
    left: 0;

    border-style: solid;
    border-width: 0 0 1.75em 3em;
    border-color: transparent transparent rgba(255, 255, 255, 0.25) transparent;
}

.vjs-theme-apprise .vjs-volume-level {
    overflow: hidden;
    background-color: transparent;
}

.vjs-theme-apprise .vjs-volume-level::before {
    content: '';
    z-index: 1;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;

    border-style: solid;
    border-width: 0 0 1.75em 3em;
    border-color: transparent transparent #AEB5BC transparent;
}
